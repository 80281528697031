import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React from "react";

type Props = {
  title?: string;
};

export const LoadingAnimation = ({
  title,
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <Box
      sx={{
        height: "200px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "primary.main",
      }}
    >
      <CircularProgress size="48px" color="inherit" sx={{ mb: 3 }} />
      {title && (
        <Typography
          sx={{
            color: "secondary.main",
            justifySelf: "center",
          }}
        >
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};
